<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Delivery</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                    @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>&nbsp;
                    <!-- <button type="button"  class= "btn btn-primary" v-on:click="exportExcel">
                        <i class="fa fa-file-excel-o"></i> Excel
                    </button> -->
                </div>

                <delivery-create-form ref="DeliveryCreateForm"/>
                <delivery-grid ref="grid" :key="gridReload" :editClick="editClick" :deleteClick="deleteClick" :statusClick="statusClick"/>
                <delivery-status-form ref="DeliveryStatusForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../shared/GlobalFunction';
import deliveryCreateForm from './Component/DeliveryCreateForm.vue';
import deliveryGrid from './Grid/DeliveryGrid.vue';
import DeliveryStatusForm from '../Delivery/Component/DeliveryStatusForm.vue';
import deliveryService from '../Delivery/Script/DeliveryServices.js';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'Delivery',
    components: {
        'delivery-create-form': deliveryCreateForm,
        'delivery-grid': deliveryGrid,
        'delivery-status-form': DeliveryStatusForm
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Delivery');
        document.getElementById("add").disabled = permission.cperms ? false : true;
        
        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$refs.DeliveryCreateForm.addClick();
        },
        editClick(id, view){
            // this.$router.push({ name: 'Delivery Form', params: { formtype:'Edit', id:id, view:view } });
            const routeData = this.$router.resolve({ name: 'Delivery Form', params: { formtype:'Edit', id:id, view:view } });
            window.open(routeData.href, '_blank');
        },
        async deleteClick(id){            
            var data = await deliveryService.getDetailDelivery(id);

            var itemGridRequestOrder = [];
            if(data.length != 0 ){
                for (let i = 0; i < data.purchasing_delivery_order_detail.length; i++) {
                    var str = { item_id: data.purchasing_delivery_order_detail[i].item_id,
                                uom: data.purchasing_delivery_order_detail[i].uom,
                                quantity: data.purchasing_delivery_order_detail[i].quantity
                            } 
                    itemGridRequestOrder.push(str);
                }
            }

            const deliveryData = {
                delivery_order_number: data.delivery_order_number,
                status: 'Delete',
                invoice_id: data.invoice_id,
                purchase_order_id: data.purchase_order_id,  
                delivery_order_date: data.delivery_order_date,
                delivery_order_received_date: data.delivery_order_received_date,
                supplier_id: data.supplier_id,
                storage_id: data.storage_id,
                notes: data.notes,
                printed_notes: data.printed_notes,
                address: data.address,
                attachment: null,
                purchasing_delivery_order_detail: itemGridRequestOrder
            }

            const variables = {
                id : parseInt(id),
                data : deliveryData
            }

            this.$swal(sweet_alert.delete).then((result) => {
                if (result.isConfirmed == true) {
                    deliveryService.editQuery(variables).then(res => {
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");                 
                    }).catch(error => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        statusClick(data) {
            this.$refs.DeliveryStatusForm.statusClick(data);
        },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        async statusFilter() {
            // var countStatus = await globalfunc.countTotal(window.location.pathname);
            // this.statusButton = await this.$globalfunc.dynamicStatusButton('ddl_status_purchasing_delivery_order', countStatus);
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
                $("#btnPosting").on('click', function() {
                    vue.$refs.grid.changeStatus('Posting');
                });
                $("#btnReceived").on('click', function() {
                    vue.$refs.grid.changeStatus('Received');
                });
                $("#btnDelete").on('click', function() {
                    vue.$refs.grid.changeStatus('Delete');
                });
            })
        },
    }
}
</script>

<style scoped>
</style>