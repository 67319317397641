<template>
    <div>
        <div class="modal fade" id="DeliveryCreateModal" tabindex="-1" aria-labelledby="DeliveryCreateModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl-custom modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="DeliveryCreateModalLabel" class="font-weight-bold">Add Delivery</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <delivery-create-grid :key="gridReload" :createClick="createClick"/>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</template>

<script>    
import deliveryCreateGrid from '../Grid/DeliveryCreateGrid.vue';

export default {
    name: 'DeliveryCreateForm',
    components: {
        'delivery-create-grid': deliveryCreateGrid,
    },
    mounted:  function () {
    },
    data: function () {
        return {
           gridReload: 0,
        }
    },
    methods: {
        addClick(){
            this.reload();
            window.$('#DeliveryCreateModal').modal('show');
        },
        createClick(id){
            window.$('#DeliveryCreateModal').modal('hide');
            this.$router.push({ name: 'Delivery Form', params: { formtype:'Add', id:id, view:'new' } });
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>
<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>