<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
            >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import deliveryServices from '../Script/DeliveryServices';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';


export default {
    name: 'DeliveryCreateGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['createClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                    page: 1,
                    pageSize: kendo_grid.default_form_grid_pagesize,
                    serverPaging: false,
                    serverFiltering: false,
                    serverSorting: false,
                    transport: {
                        read: {
                            contentType: "application/json",
                            url: apiBaseUrl,
                            type: "POST",
                            data: function(e) {
                                return { 
                                    query: deliveryServices.readPurchasingDeliveryOrderableQuery()
                                };
                            },
                            beforeSend: function (req) {
                                req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                req.setRequestHeader('Path', window.location.pathname);
                            }
                        },
                        parameterMap: function(options, operation) {
                            return  kendo.stringify({
                                query: options.query,
                                variables: options.variables
                            });
                        },
                        cache: false,
                    },
                    schema: {
                        data: function (response) {
                            if (response.data.GetPurchasingDeliveryOrderableItemListV2 == null) {
                                return [];
                            }
                            else {
                                return response.data.GetPurchasingDeliveryOrderableItemListV2;
                            }
                        },
                        total: function (response) {
                            if (response.data.GetPurchasingDeliveryOrderableItemListV2 == null) {
                                return 0;
                            }
                            else {
                                return response.data.GetPurchasingDeliveryOrderableItemListV2.length;
                            }
                        }
                    }
                }),
            columns:  [
                { title: "Action", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" }, attributes: { "class": "k-text-center" },
                    template: '<div class="btn-group"> <button type="button" class="btn btn-success btn-md" id="CreateButton"> Create </button> </div>'
                },
                { field: "vendor_name", title: "Vendor", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" } },
                { field: "purchase_order_number", title: "Nomor PO", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {
        var grid = this.$refs.grid.kendoWidget();
        var createClick = this.$props.createClick;

        var GridElement = grid.element;

        GridElement.on("click", "#CreateButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            createClick(dataItem.purchase_order_id)
        })
    },
    methods: {
       
    }
}
</script>
<style scoped>
</style>
