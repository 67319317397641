<template>
    <div>
        <input hidden type="text" class="form-control" id="DeliveryId" v-model="DeliveryId">
        <div class="modal fade" id="DeliveryStatusModal" tabindex="-1" aria-labelledby="DeliveryStatusModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="DeliveryStatusModalLabel" class="font-weight-bold">{{ModalTitle}}{{DeliveryNo}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                        </div>
                       
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import {globalfunc} from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import deliveryService from '../Script/DeliveryServices.js';
import { response } from '../../../../infrastructure/constant/response';
import { sweet_alert } from '../../../../infrastructure/constant/variable';

export default {
    name: 'DeliveryStatusForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',

            DeliveryId : '',
            DeliveryNo : '',
            Status : '',
            StatusData : [],
        }
    },
    methods: {
        async statusClick(deliveryData){
            this.ModalTitle = 'Edit Status Purchasing Delivery: ';
            this.DeliveryId = deliveryData.delivery_order_id;
            this.DeliveryNo = deliveryData.delivery_order_number;
            
            this.StatusData = await globalfunc.globalDropdown('ddl_status_purchasing_delivery_order');
            this.Status = deliveryData.status;
            
            window.$('#DeliveryStatusModal').modal('show');
        },
        async saveClick(){
            var data = await deliveryService.getDetailDelivery(this.DeliveryId);

            var itemGridRequestOrder = [];
            if(data.length != 0 ){
                for (let i = 0; i < data.purchasing_delivery_order_detail.length; i++) {
                    var str = { item_id: data.purchasing_delivery_order_detail[i].item_id,
                                uom: data.purchasing_delivery_order_detail[i].uom,
                                quantity: data.purchasing_delivery_order_detail[i].quantity
                            } 
                    itemGridRequestOrder.push(str);
                }
            }

            const deliveryData = {
                delivery_order_number: data.delivery_order_number,
                status: this.Status,
                invoice_id: data.invoice_id,
                delivery_order_date: data.delivery_order_date,
                delivery_order_received_date: data.delivery_order_received_date,
                supplier_id: data.supplier_id,
                storage_id: data.storage_id,
                notes: data.notes,
                printed_notes: data.printed_notes,
                address: data.address,
                attachment: null,
                purchase_order_id: data.purchase_order_id,
                purchasing_delivery_order_detail: itemGridRequestOrder
            }

            const variables = {
                id : parseInt(this.DeliveryId),
                data : deliveryData
            }
            
            this.$swal(sweet_alert.update_status).then((result) => {
                if (result.isConfirmed == true) {
                    deliveryService.editQuery(variables).then(res => {
                        this.reload();
                        window.$('#DeliveryStatusModal').modal('hide');
                        this.$swal("Info", response.successUpdate, "success");                 
                    }).catch(error => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>